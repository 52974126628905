import { Box, Container, Heading, SpaceProps, Text } from '@chakra-ui/react'
import { SPOONFLOWER_BASE_URL } from '@modules/app'
import { Link } from '@modules/app/components/Link'
import useWhoAmI from '@modules/app/hooks/useWhoAmI'
import { useIntl } from 'react-intl'

export interface CartSaveForLater {
  paddingTop?: SpaceProps['paddingTop']
  paddingBottom?: SpaceProps['paddingBottom']
}

export const CartSavedForLater = ({
  paddingTop,
  paddingBottom,
}: CartSaveForLater) => {
  const { data: user } = useWhoAmI()
  const userLocale = user?.preferences?.locale ?? 'en'

  const intl = useIntl()
  const title = intl.formatMessage({ id: 'cart.saveForLater.title' })
  const comingSoon = intl.formatMessage({ id: 'cart.saveForLater.comingSoon' })
  const link = intl.formatMessage({ id: 'cart.saveForLater.linkTitle' })
  const description = intl.formatMessage(
    { id: 'cart.saveForLater.description' },
    {
      savedForLaterLink: (
        <Link href={`${SPOONFLOWER_BASE_URL}/${userLocale}/save-for-later`}>
          <Text as="span" fontWeight="bold">
            {link}
          </Text>
        </Link>
      ),
    }
  )

  return (
    <Box
      borderRadius="none"
      width={{ base: '100vw', md: '100%' }}
      marginTop={{ base: '2', md: '0' }}
      marginX={{ base: '-6', md: '0' }}
      paddingX={{ base: '6', md: '0' }}
      paddingTop={paddingTop ?? { base: '10', md: '24' }}
      paddingBottom={paddingBottom ?? { base: '10', md: '0' }}
    >
      <Container
        maxWidth="full"
        overflow="hidden"
        paddingX="0"
        position="relative"
        zIndex="1"
      >
        <Heading
          as="h2"
          marginBottom={{ base: '3', md: '4' }}
          marginTop={{ base: '0', md: '0' }}
          fontSize={{ base: '2xl', md: '4xl' }}
          lineHeight={'125%'}
          variant="serif"
        >
          <Text
            as="span"
            color="primary-text"
            lineHeight={'125%'}
            textStyle="title-case"
          >
            {title}{' '}
          </Text>
          <Text
            as="span"
            display={'inline-block'}
            color="tertiary-text"
            lineHeight={'125%'}
            marginTop={'2'}
          >
            {comingSoon}
          </Text>
        </Heading>
        <Text
          color="gray.500"
          fontSize={{ base: 'md', md: 'lg' }}
          lineHeight={{ base: '6', md: '125%' }}
        >
          {description}
        </Text>
      </Container>
    </Box>
  )
}
